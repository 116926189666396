import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import '../ButtonMasterSetting/style.scss';

const CustomNavLink = ({ to, text, svg }) => {
  const [width, setWidth] = useState('auto');
  useEffect(() => {
    const characters = text.split('');
    if (characters.length <= 4) {
      setWidth('62px');
    } else {
      setWidth('auto');
    }
  }, [text]);
  const location = useLocation();
  let isActive = false;
  if (to) {
    isActive = location.pathname === to || new URLSearchParams(location.search).get('p') === to.split('=')[1];
  }

  return to ? (
    <NavLink
      className={`nav-link ${isActive ? 'active-menu-master-setting' : ''}`}
      to={to}
    >
      <div className="w-100 d-flex wrapper-button d-flex align-items-center justify-content-center flex-column">
        <div dangerouslySetInnerHTML={{ __html: svg }} />
        <div style={{ marginTop: '8px', fontSize: '12px', fontWeight: '700', fontFamily: 'Noto Sans JP', height: '26px', textAlign: 'center', width: width}}>
          <p>{text}</p>
        </div>
      </div>
    </NavLink>
  ) : (
    <div className="w-100 d-flex wrapper-button d-flex align-items-center justify-content-center flex-column nav-link">
      <div dangerouslySetInnerHTML={{ __html: svg }} />
      <span style={{ marginTop: '8px', fontSize: '12px', fontWeight: 'bold', fontFamily: 'Noto Sans JP', height: '26px'}}>{text}</span>
    </div>
  );
};

CustomNavLink.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  svgPath: PropTypes.string.isRequired,
};

export default CustomNavLink;
