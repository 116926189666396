import React, { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './style.scss';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { translate } from 'react-i18next';
import { MASTER_SETTING, COLOR_ENDPOINT } from '../../../../constants/endpoints';
import { pushToast } from '../../../../components/Toast';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { getColorMe, getLogo } from '../../../../actions/utils';
import { LIST_COLOR } from 'helpers/constants';
import { SCREEN_NAME, ROLE } from '../../../../helpers/constants';
import Loading from 'components/Loading/Loading';
import { getRole, getRoleColor } from 'helpers/helpers';
import _ from 'lodash';
import { getDataHistoryMasterSetting } from 'actions/authentication';

const mockData = {
  center: {},
  partner: {},
  client: {},
  screenName: SCREEN_NAME.MY_CORP,
}

const InfoCompany = ({idProvide, dataColorOrg }) => {
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const dispatch = useDispatch();
  const [logoSelected, setLogoSelected] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [dataColor, setDataColor] = useState(mockData);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState('');
  const dispatchAddToCart = (message) => dispatch(getColorMe(message));
  const dispatchAddLogoToCart = (message) => dispatch(getLogo(message));
  const idRole = localStorage.getItem('role');
  const idProvider = localStorage.getItem('idProvide');
  const availableBgColor = LIST_COLOR.map(item => item.code);
  const defaultBgColor = "#E2E2E6";

  const onChangeLogo = (e) => {
    setSelectedFile(e.target.files[0]);
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setLogoSelected(reader.result);
    });
    reader.readAsDataURL(e.target.files[0]);
  };

  const handleChangeColor = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    const pickColor = _.pick({...dataColor[name]}, ['codeColor', 'colorText']);
    const newData = {...dataColor, [name]: {...pickColor, codeColor: value?.code, colorText: value?.colorText}};
    setDataColor(newData);
  };

  const handleRoleColor = () => {
    switch (role) {
      case ROLE.CENTER:
      case ROLE.PARTNERGROUP:
        return "center";
      case ROLE.PARTNER:
        return "partner";
      case ROLE.CLIENT:
        return "client";
      default:
        return "center";
    }
  }

  useEffect(() => {
    if(dataColorOrg){
      const pickColorCenter = _.pick({...dataColorOrg?.center}, ['codeColor', 'colorText']);
      const pickColorPartner = _.pick({...dataColorOrg?.partner}, ['codeColor', 'colorText']);
      const pickColorClient = _.pick({...dataColorOrg?.client}, ['codeColor', 'colorText']);
      setDataColor({ ...colorScreen, ...dataColorOrg, center: pickColorCenter, partner: pickColorPartner, client: pickColorClient })
      setLogoSelected(dataColorOrg?.logo || null);
    }
  }, [dataColorOrg]);

  useEffect(() => {
    setRole(getRole({ idRole }));
  }, [idRole]);

  const getColorInfo = async ({ idProvide }) => {
    setLoading(true);
    try {
      const data = await MASTER_SETTING.GET_LIST_COLOR_BY_ORGANIZATION(1, idProvide);
      if (data) {
        if(idProvide === idProvider) {
          const color = data?.data?.[getRoleColor({idRole})];
          const colorMaster = {
            code: color?.codeColor,
            colorText: color?.colorText,
            colorId: data?.data?.colorId,
          }
          dispatchAddLogoToCart(data?.data);
          dispatchAddToCart(colorMaster);
        }
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const updateOrderInfo = async ({ body = {}, colorId = '', idProvide = '' }) => {
    setLoading(true);
    if (dataColor.colorId) {
      try {
        const data = await MASTER_SETTING.UPDATE_COLOR({ body, colorId, idProvide });
        if (data && data.code !== 0) {
          if (data && data.message && data.message.extra) {
            pushToast('error', data?.message?.extra);
          } else {
            pushToast('error', data?.message?.errorMessage);
          }
        } else {
          pushToast('success', 'Update order success');
          localStorage.setItem('color',  dataColor?.[handleRoleColor()]?.codeColor);
          getColorInfo({ idProvide });
          dispatch(getDataHistoryMasterSetting({ logId: '', screenName: SCREEN_NAME.MY_CORP, idProvide }));
        }
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    } else {
      try {
        body.append('organizationId', dataColor.organizationId);
        const data = await MASTER_SETTING.CREATE_COLOR({ body });
        if (data && data.code !== 0) {
          if (data && data.message && data.message.extra) {
            pushToast('error', data?.message?.extra);
          } else {
            pushToast('error', data?.message?.errorMessage);
          }
        } else {
          pushToast('success', 'Update order success');
          localStorage.setItem('color',  dataColor?.[handleRoleColor()]?.codeColor);
          dispatch(getDataHistoryMasterSetting({ logId: '', screenName: SCREEN_NAME.MY_CORP }));
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const onUpdateColor = async () => {
    const newData = _.pick(dataColor, ['center', 'partner', 'client'])
    const body = { ...newData, screenName : SCREEN_NAME.MY_CORP, logo: selectedFile ? (await toBase64(selectedFile)) : '', updateTitle : '保存 企業情報'}
    updateOrderInfo({ body, colorId: dataColor.colorId, idProvide: idProvide });
  };

  const selectedValue = (codeColor) => {
    if (availableBgColor.includes(codeColor)) return codeColor;

    return defaultBgColor
  }

  return (
    <div className="col-12 row form__info-company--none-space">
      <Loading loading={loading} />
      <div className="col-6">
        <div className="row form__info-company--height-input">
          <div className="form__info-company--with-mix form__info-company--label">CloudID</div>
          <div className="form__info-company--with-mix form__info-company--label">{dataColor?.cloudId}</div>
        </div>
        <div className="row form__info-company--height-content">
          <div className="form__info-company--with-mix form__info-company--label">ロゴ</div>
          <div className="form__info-company--with-mix form__info-company--content">
            <img className="form__info-company--logo" src={logoSelected || '/img/icons.png'} alt=""/>
            <div className="form__info-company--input" htmlFor='upload-csv'>
              <label htmlFor="upload-logo">画像選択</label>
              <input
                type="file"
                name="logo"
                id="upload-logo"
                value=""
                onChange={onChangeLogo} />
            </div>
          </div>
        </div>
      </div>
      <div className="col-6">
        <div className="row form__info-company--height-input">
          <div className="form__info-company--with-mix form__info-company--label">企業名</div>
          <div className="form__info-company--with-mix form__info-company--label">{dataColor?.companyName}</div>
        </div>
        <div className="row form__info-company--height-input">
          <div className="form__info-company--with-mix form__info-company--label">{role === ROLE.PARTNERGROUP ? 'ManagementGroup背景色' : 'ERP：契約企業'}</div>
          <div className="form__info-company--with-mix">
            <Select
              name="center" value={dataColor?.center?.codeColor}
              onChange={handleChangeColor} id="color-center"
              className="form__info-company--select-color" style={{ backgroundColor: availableBgColor.includes(dataColor?.center?.codeColor) ? `${dataColor?.center?.codeColor}` : defaultBgColor}}
            >
              {
                LIST_COLOR.map((item, index) =>
                  <MenuItem value={item} key={index} style={{backgroundColor: item?.code}}>{item.name}</MenuItem>
                )
              }
            </Select>
          </div>
        </div>
        <div className="row form__info-company--height-input">
          <div className="form__info-company--with-mix form__info-company--label">Partner：協力企業</div>
          <div className="form__info-company--with-mix">
            <Select
              name="partner" value={dataColor?.client?.codeColor}
              onChange={handleChangeColor} id="color-partner"
              className="form__info-company--select-color" style={{ backgroundColor: availableBgColor.includes(dataColor?.partner?.codeColor) ? `${dataColor?.partner?.codeColor}` : defaultBgColor}}
            >
              {
                LIST_COLOR.map((item, index) =>
                  <MenuItem value={item} key={index} style={{backgroundColor: item?.code}}>{item.name}</MenuItem>
                )
              }
            </Select>
          </div>
        </div>
        <div className="row form__info-company--height-input">
          <div className="form__info-company--with-mix form__info-company--label">Client：発注企業</div>
          <div className="form__info-company--with-mix">
            <Select
              name="client" value={dataColor?.client?.codeColor}
              onChange={handleChangeColor} id="color-client"
              className="form__info-company--select-color" style={{ backgroundColor: availableBgColor.includes(dataColor?.client?.codeColor) ? `${dataColor?.client?.codeColor}` : defaultBgColor}}
            >
              {
                LIST_COLOR.map((item, index) =>
                  <MenuItem value={item} key={index} style={{backgroundColor: item?.code}}>{item.name}</MenuItem>
                )
              }
            </Select>
          </div>
        </div>
      </div>
      <button className="form__info-company--btn-submit" onClick={onUpdateColor} >保存</button>
    </div>
  );
};


export default compose(translate('translations'), withRouter)(InfoCompany);
