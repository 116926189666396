import { ACTIONS } from '../actions/utils';
import { LIST_COLOR } from '../helpers/constants';

export const utilsState = {
    loading: false,
    paramSearch: {},
    colorMe: {},
    logo: {}
};

export default (state = utilsState, action) => {
  const defaultBgColor = "#E2E2E6";
  const defaultTextColor = "#333";
  const availableBgColor = LIST_COLOR.map(item => item.code);

  const overrideBgColor = (color) => {
    if (color && availableBgColor.includes(color)) return color;

    return defaultBgColor;
  }

  const overrideTextColor = (bgColor, textColor) => {
    if (textColor && availableBgColor.includes(bgColor)) return textColor;

    return defaultTextColor;
  }

  switch (action.type) {
      case ACTIONS.SHOW_LOADING:
          return {
              ...state,
              loading: true,
          };
      case ACTIONS.HIDE_LOADING:
          return {
              ...state,
              loading: false,
          };

      case ACTIONS.GET_PARAM_SEARCH:
          return {
              ...state,
              loading: false,
              paramSearch: action.payload
          };
      case ACTIONS.GET_COLOR_ME:
          const colorBg = action.payload.code;
          const colorText = action.payload.colorText;

          const currentColorBg = overrideBgColor(colorBg);
          const currentColorText = overrideTextColor(colorBg, colorText);
          const currentColorMe = { ...action.payload, code: currentColorBg, colorText: currentColorText };

          return {
              ...state,
              colorMe: currentColorMe
          };
      case ACTIONS.GET_LOGO:
          return {
              ...state,
              logo: action.payload
          };
      default:
          return state;
  }
};
