import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { ROLE_TYPE_ENUM, ROLE_NAME_ENUM, PERMISSION } from '../../../helpers/constants';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import './style.scss';
import { COLOR_ENDPOINT } from '../../../constants/endpoints';
import { getColorMe } from '../../../actions/utils';
import { DISABLE_MENU_LIST } from '../../../helpers/constants';
import Loading from '../../../components/Loading/Loading';
import _ from 'lodash';
import { RootPath } from './services';

const SelectRole = (props) => {
  const loading = useSelector((store) => store.utils.loading);
  const { history } = props;
  const userMe = useSelector((store) => store.authentication.roleMe, shallowEqual);
  const userMeData = useSelector((store) => store.authentication.userMe, shallowEqual);
  const [role, setRole] = useState(localStorage.getItem('role'));
  const [provider, setProvider] = useState(localStorage.getItem('provide'));
  const [idProvider, setIdProvider] = useState(localStorage.getItem('idProvide'));
  const [permissionId, setPermissionId] = useState(localStorage.getItem('permissionId'));
  const [permissionIdGroup, setPermissionIdGroup] = useState(localStorage.getItem('permissionIdGroup'));
  const [companyName,setCompanyName] = useState(localStorage.getItem('companyName'))
  const dispatch = useDispatch();
  const [moduleManager, setModuleManager] = useState({});
  const dispatchAddToCart = (message) => dispatch(getColorMe(message));
  const [data, setData] = useState(userMe?.data?.dataAuthority);
  const [dataUserMe, setDataUserMe] = useState(userMeData?.data);
  let clickCount = 0;

  const getColorssss = async ({ role = '' }) => {
    try {
      const data = await COLOR_ENDPOINT.GET_COLOR({ role });
      if (data && data?.code === 0) {
        dispatchAddToCart(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const dataRole = (userMe?.data?.moduleManagement || []).map((item) => {
      let role_center = (!item.center && DISABLE_MENU_LIST.ROLE_ERP) || [];
      let role_partner = (!item.partner && DISABLE_MENU_LIST.ROLE_PARTNER) || [];
      let role_monitoring = (!item.client && DISABLE_MENU_LIST.ROLE_MONITORING) || [];
      const arr_tmp = _.union(role_center, role_monitoring, role_partner);
      return {
        organizationId: item.organizationId,
        data: arr_tmp,
      };
    });
    setData(userMe?.data?.dataAuthority);

    setModuleManager(_.mapValues(_.keyBy(dataRole, 'organizationId'), 'data'));
    localStorage.setItem('idProvide', userMe?.data?.dataAuthority?.[0]?.idProvider);
    localStorage.setItem('provide', 'Organization');
  }, [userMe]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false);

    return () => {
      document.removeEventListener('keydown', handleKeyDown, false);
    };
  }, [role, permissionId])

  const onClickSubmit = () => {
    //if(!_.find(data, {provider: "Organization", idRole: role, permissionId: permissionId || ""  , companyName : companyName || "" })) return;
    localStorage.removeItem('listMenu')
    localStorage.setItem('role', role);
    localStorage.setItem('companyName', companyName);
    if(['5','6','7'].includes(role)) {
      localStorage.setItem('permissionId' , '');
      localStorage.setItem('permissionIdGroup', permissionIdGroup);
    } else {
      localStorage.removeItem('permissionIdGroup');
      localStorage.setItem('permissionId', permissionId);
    }

    const rootPath = RootPath(role);
    history.push(rootPath);
    getColorssss({ role });
  };

  const handleSelectRole = (item) => {
    setRole(item?.idRole);
    setProvider(item?.provider);
    setIdProvider(item?.idProvider);
    setPermissionId(item?.permissionId);
    setCompanyName(item?.companyName);
    setPermissionIdGroup(item?.permissionId);
    clickCount++;
    setTimeout(function() {
      clickCount = 0;
    }, 300);
    if(clickCount == 2 ){
        onClickSubmit();
    }
  };

  const handleKeyDown = (e) => {
    if (!!role && e.key === 'Enter') {
      onClickSubmit();
    }
  };

  return (
    <div className="main">
      <Loading loading={loading} />
      <Header idRole={role} />
      <div className="body-wrapper">
        <div className="select-role-wrapper mt-4 text-center">
          <div className="choose-role-wrapper d-flex justify-content-center">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>システム</th>
                  <th>企業名</th>
                  <th>権限</th>
                  <th>選択</th>
                </tr>
              </thead>
              <tbody>
                {_.sortBy(data, 'permissionId').map((item, index) => {
                      if (item.idRole === '11') return;

                      return (
                        !_.isEmpty(moduleManager) &&
                        !moduleManager[item.idProvider].includes(item.idRole) && (
                          <tr key={index} onClick={() => handleSelectRole(item)} >
                            <td>
                              <label className="role-label mb-0">
                                {
                                  ROLE_NAME_ENUM[
                                    item?.idRole === '14' ?
                                      ['2', '3', '4', '5', '6', '7'].includes(dataUserMe?.primaryRole?.idRole) ?
                                        '8'
                                      :
                                        dataUserMe?.primaryRole?.idRole ?
                                          dataUserMe?.primaryRole?.idRole
                                        :
                                          _.minBy(data, (o) => parseInt(o.idRole))?.idRole
                                    :
                                      item?.idRole
                                  ]
                                }
                              </label>
                            </td>
                            <td>
                              <label className="role-label mb-0">{item?.companyName}</label>
                            </td>
                            <td>
                              <label className="role-label mb-0" htmlFor={item?.idRole}>
                                {ROLE_TYPE_ENUM[item?.idRole]}
                              </label>
                            </td>
                            <td>
                              <input
                                onChange={() => handleSelectRole(item)}
                                type="radio"
                                className=""
                                id={item?.idProvider}
                                value={item?.idRole}
                                name="Provider"
                                data-provider={item?.provider}
                                data-permission={item?.permissionId}
                                checked={
                                  item?.idRole === role
                                }
                              />
                            </td>
                          </tr>
                        )
                      );
                }
                  )}
              </tbody>
            </table>
          </div>
          <button className="btn button-select-role" onClick={onClickSubmit} disabled={!role}>
            決定
          </button>
        </div>
      </div>
      <Footer color={'#fff'} colorText={'#212529'}/>
    </div>
  );
};

export default compose(withRouter)(SelectRole);
