import React from 'react';
import { compose } from 'redux';
import { translate } from 'react-i18next';
import { HeaderIconByPage, HeaderIconUrl } from '../HeaderIcon/index';
import { HEADER_SETTING_BY_ROLE } from '../../../helpers/constants';
import CustomNavLink from '../ButtonMasterSetting/navLink';

const PartnerMenu = ({ idRole, t, colorText }) => {
  const headerIcon = HeaderIconByPage();
  const headerIconUrl = HeaderIconUrl();
  const headerByRole = HEADER_SETTING_BY_ROLE[idRole];
  return (
    <div style={{marginTop: '8px'}}>
      {headerByRole.map((page) => {
        return (
          <div className="page_icon" key={page}>
            <CustomNavLink
              to={headerIconUrl[page]}
              text={t(`menu_partner.${page}`)}
              svg={headerIcon[page]}
            />
          </div>
        );
      })}
    </div>
  );
};

export default compose(translate('translations'))(PartnerMenu);
