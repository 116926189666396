import { PERMISSION, ENUM_ROLE } from '../../../../helpers/constants';

export const RootPath = (role) => {
  if (role == ENUM_ROLE.WORK) return '/workerList?&workName=today';
  if (PERMISSION.PERMISSION_MAINTAIN_MYCORP.includes(role)) return '/master-setting';
  if (PERMISSION.PERMISSION_MAINTAIN_STAFF.includes(role)) return '/organizations';
  if (PERMISSION.PERMISSION_PARTNER_CONTRACT.includes(role)) return '/contract';

  return '/list?p=receive_all';
}
