const ja_language = {
  table_order_list: {
    result: '作業結果',
    dateDelivery: '英語',
    // process: '工程',
    websiteVisited: '物件名',
    partner: 'パートナー名',
    district: '都道府県',
    dateReceive: '報告書受領日',
    dateVisit: '指定訪問日時',
    controlId: '管理コード',
    dateRequest: '依頼日',
    projectName: 'プロジェクト名',
    companyCandidateName: '申込会社名',
    reportDeliveryDate: '報告書納品日',
    contactName: '担当者名',
    managementCode: '管理コード',
    process: '工程',
    applicantCompanyName: '申込会社名',
    personInChargeOfApplication: '申込ご担当者',
    contactPersonForApplication: '申込ご担当者連絡先',
    installationSiteName: '物件名',
    contactPersonInCharge: '訪問先ご担当者',
    contactInformation: '訪問先ご担当者連絡先',
    visitedPostalCode: '訪問先郵便番号',
    prefecturesVisited: '都道府県',
    visitedBaseAddress: '訪問先拠点住所',
    visitedBuildingName: '訪問先建物名',
    scheduledInstallationLocation: '作業予定箇所',
    notificationItems: '申し送り事項',
    desiredDayToVisit1: '訪問希望日①',
    desiredVisitDateAndTime1: '希望訪問日時①（開始時間帯_from)①',
    desiredDateAndTimeOfVisit1: '希望訪問日時①（開始時間帯_to)',
    desiredDayToVisit2: '訪問希望日②',
    desiredDateAndTimeOfVisit2: '希望訪問日時②（開始時間帯_from)',
    desiredDateAndTimeOfVisit3: '希望訪問日時③（開始時間帯_from）',
    desiredDateAndTimeOfVisit4: '希望訪問日時④（開始時間帯_from）',
    desiredDateAndTimeOfVisit5: '希望訪問日時⑤（開始時間帯_from）',
    visitDate: '訪問日',
    dateAndTimeOfVisitFrom: '訪問日時（開始時間帯_from）',
    dateAndTimeOfVisitTo: '訪問日時（開始時間帯_to）',
    visitingTime: '訪問時間帯',
    partnerId: 'パートナーID',
    worker1Id: '作業員1_ID',
    worker2Id: '作業員2_ID',
    worker3Id: '作業員3_ID',
    status: '状況',
    requestedDate: '作成日',
    caseName: 'Product',
    partnerName: '協力企業名',
    canceledAt: 'キャンセル日',
    resultCancel: '結果備考',
    visitedSiteName: '物件名',
    workerReport: 'Worker報告書',
    exitTime: '退館時間',
    entryTime: '入館時間',
    operationAdjustmentDate: '稼働調整日',
    timeAddPartner: '稼働調整日',
    workerResponseRequired: '作業員返答区分',
    workerName: '作業員名1',
    adjustmentStatus: '調整状況',
    admissionTime: '入館時間',
    reportReceiptDate: '報告書受領日',
    finalPowerDay: '最終架電日',
    lastCallLog: '架電メモ',
    reportReturnDate: '不備返却日',
    defectiveReturnDetails: '不備返却コメント',
    dateOfSendingFieldDocuments: '現場資料添付日',
    messageForPartnerCommunication: '契約企業申し送り事項',
    reportConfirmedAt: '報告書提出日',
    reasonForCancellation: '結果備考',
    clientDefectReturnDate: 'Monitoring_不備返却日',
    clientDeficiencyReturnContent: 'Monitoring_不備返却コメント',
    resultRemarks: '結果備考',
    resultsPreparation: '作業結果',
    startTime: '入館時間',
    endTime: '退館時間',
    serviceName: 'Service',

    defectiveReturnContents: '不備返却コメント',
    visitDate1: '希望日時 1',
    visitDate2: '希望日時 2',
    visitDate3: '希望日時 3',
    visitDate4: '希望日時 4',
    visitDate5: '希望日時 5',
    timestampReportUploadInfo: '報告書納品日',
    acceptanceDate: '検収日',
    settlementDay: '精算日',
    timestampReceiveInfo: '承認日',
    callStatus: '架電状況',
    newCallDate: '次回架電日',
    timeAddPartner: '打診日',
    tomorrowDetailStatus: '事前確認状況',
    statusWorker: '作業員対応状況',
    statusWorkerAllocation: '作業員対応状況',
    timestampInfo: '報告書受領日',
    timestampDeliveryInfo: '報告書納品日',
    erpMattersToBeSent: '契約企業申し送り事項',
    callLog: '架電メモ',
    resultCancelInfo: '結果',
  },

  statusBeforeConfirm: {
    waitingForResponse: '対応待ち',
    underAdmissionApplication: '対応中',
    correspondenceCompleted: '対応完了',
    cancelRequest: 'キャンセル依頼',
    cancel: 'キャンセル',
    withdrawal: '対応保留',
    workerUnallocated: '対応待ち',
    workersAreBeingAssigned: '対応中',
    preConfirmationCompleted: '対応完了',
    confirmationNotCompletedTheDayBefore: '前日確認未完了',
    operationCheck: '稼働確認',
    waitingForAdmission: '入館待ち',
    preConfirmationNotCompleted: '事前確認未完了',
    undefined: '',
    '': '',
  },

  table_order_list_center: {
    messageForPartnerCommunication: '契約企業申し送り事項',
    caseName: '工事名',
    managementCode: '管理コード',
    process: '工程',
    applicantCompanyName: '申込会社名',
    visitedSiteName: '物件名',
    requestedDate: '作成日',
    visitDate: '指定訪問日時',
    notificationItems: '発注企業申し送り事項',
    reportDeliveryDate: '報告書納品日',
    result: '作業結果',
    prefecturesVisited: '都道府県',
    partnerName: '協力企業名',
    reportReceiptDate: '報告書受領日',
    finalPowerDay: '最終架電日',
    lastCallLog: '架電メモ',
    operationAdjustmentDate: '稼働調整日',
    timeAddPartner: '稼働調整日',
    workerResponseRequired: '作業員返答',
    workerName: '作業員名',
    adjustmentStatus: '調整状況',
    admissionTime: '入館時間',
    exitTime: '退館時間',
    workerReport: 'Worker報告書',
    reportReturnDate: '不備返却日',
    defectiveReturnContents: '不備返却コメント',
    reportConfirmedAt: '報告書提出日',
    canceledAt: 'キャンセル日',
    resultCancel: '結果備考',
    startTime: '入館時間',
    endTime: '退館時間',
    resultsPreparation: '作業結果',
    serviceName: '工程名',
    acceptanceDate: '検収日',
    projectName: 'プロジェクト名',
    settlementDay: '精算日',
    timestampReportUploadInfo: '報告書納品日',
    callStatus: '架電状況',
    newCallDate: '次回架電日',
    timestampReceiveInfo: '受注承認日',
    timeAddPartner: '打診日',
    tomorrowDetailStatus: '事前確認状況',
    statusWorker: '作業員対応状況',
    statusWorkerAllocation: '作業員対応状況',
    timestampInfo: '報告書受領日',
    timestampDeliveryInfo: '報告書納品日',
    installationSiteName: '物件名',
    erpMattersToBeSent: '契約企業申し送り事項',
    callLog: '架電メモ',
    resultCancelInfo: '作業結果',
    statusMonitoring: '事前確認状況',
    statusWorkerReport: 'Worker報告書',
    reasonForCancellation: '結果備考',
    visitedBaseAddress: '訪問先拠点住所',
  },

  table_order_list_partner: {
    projectName: 'プロジェクト名',
    installationSiteName: '物件名',
    reportReceiptDate: '報告書受領日',
    timestampReportUploadInfo: '報告書納品日',
    acceptanceDate: '検収日',
    settlementDay: '精算日',
    resultsPreparation: '作業結果',
    caseName: '工事名',
    managementCode: '管理コード',
    process: '工程',
    applicantCompanyName: '申込会社名',
    visitedSiteName: '物件名',
    operationAdjustmentDate: '稼働調整日',
    timeAddPartner: '稼働調整日',
    prefecturesVisited: '都道府県',
    visitedBaseAddress: '訪問先拠点住所',
    messageForPartnerCommunication: '契約企業申し送り事項',
    visitDate: '訪問日',
    workerName: '作業員名',
    requestedDate: '作成日',
    partnerName: '協力企業名',
    finalPowerDay: '最終架電日',
    lastCallLog: '架電メモ',
    canceledAt: 'キャンセル日',
    resultCancel: '結果備考',
    admissionTime: '入館時間',
    exitTime: '退館時間',
    workerReport: 'Worker報告書',
    result: '作業結果',
    status: 'ステータス',
    resultRemarks: '結果備考',
    reportConfirmedAt: '報告書提出日',
    visitDateFrom: '開始予定時間',
    visitDateTo: '終了予定時間 ',

    startTime: '入館時間',
    endTime: '退館時間',
    serviceName: '工程名',
    erpMattersToBeSent: '契約企業申し送り事項',
    newCallDate: '次回架電日',
    callStatus: '架電状況',
    callLog: '架電メモ',
    statusMonitoring: '事前確認状況',
    statusWorkerReport: 'Worker報告書',
    statusWorkerAllocation: '作業員対応状況',
    tomorrowDetailStatus: '事前確認状況',
    statusWorker: '作業員対応状況',
    reasonForCancellation: '結果備考',
    resultCancelInfo: '結果',
  },

  table_order_list_worker: {
    caseName: '案件名',
    managementCode: '管理コード',
    serviceName: '工程',
    prefecturesVisited: '物件名',
    applicantCompanyName: '申込会社名',
    visitDate: '訪問日',
    visitDateFrom: '開始予定時間',
    visitDateTo: '終了予定時間',
    status: 'ステータス',
  },

  translation_pushtoast: {
    PASSWORD_MUST_CONTAIN_AT_LEAST_ONE_LOWERCASE_LETTER_ONE_CAPITAL_LETTER_ONE_NUMBER_AND_8_CHARACTERS_OR_MORE:
      'パスワードは8文字以上（数字、英語大文字、英語小文字、記号の組合せ）が必要です。',
    DOCUMENT_NOT_UPDATE: '新パスワードとパスワード確認が一致しません。',
  },

  table_order_list_client: {
    resultsPreparation: '作業結果',
    caseName: '工事名',
    contactName: '発注企業担当者名',
    requestedDate: '作成日',
    managementCode: '管理コード',
    process: '工程',
    applicantCompanyName: '申込会社名',
    visitedSiteName: '物件名',
    prefecturesVisited: '都道府県',
    visitDate: '訪問日',
    resultCancel: '結果備考',
    reasonForCancellation: '結果備考',
    resultCancelInfo: 'キャンセル結果',
    admissionTime: '入館時間',
    exitTime: '退館時間',
    status: '状況',
    clientDefectReturnDate: '不備返却日',
    clientDeficiencyReturnContent: '不備返却コメント',
    partnerName: '担当者名',
    reportConfirmedAt: '報告書提出日',

    startTime: '入館時間',
    endTime: '退館時間',
    serviceName: '工程名',
    erpMattersToBeSent: '契約企業申し送り事項',
    installationSiteName: '物件名',
    timeAddPartner: '稼働調整日',
    reportReceiptDate: '報告書受領日',
    statusMonitoring: '事前確認状況',
    statusWorker: '作業員対応状況',
    statusWorkerReport: 'Worker報告書',
    statusWorkerAllocation: '作業員対応状況',
  },

  project_title: {
    beforeConstruction: '工事前',
    afterConstruction: '工事後',
    completed: '完了済',
    cancel: 'キャンセル',
    approvalPending: '承認待ち',
    waitingForAppointment: 'アポイント待ち',
    waitingForOperationSetting: '稼働設定待ち',
    waitingForOperationReply: '稼働回答待ち',
    waitingForOperationApproval: '稼働承認待ち',
    notOperational: '稼働不可',
    waitingForOperation: '稼働待ち',
    waitingForAdmission: '入館待ち',
    duringAdmission: '入館中',
    done: '完了',
    incomplete: '未完了',
    canOnTheDay: '当日CAN',
    uncollected: '未回収',
    defectiveReturn: '不備返却',
    reportCollected: '報告書回収済',
    reportApproved: '報告書承認済み',
    unregisteredWorker: '作業員未登録',
    unconsulted: '未打診',
    operable: '稼働可',
    reportUncollected: '報告書未回収',
    reportDelivered: '報告書納品済',
    waitingForApproval: '未回答',
    unanswered: '稼働可承認待ち',
    submitted: '提出済',
    notSubmitted: '未提出',
  },

  detail_title_list_order: {
    clientCompany: '工事情報',
    applicationCompany: '物件情報',
    stileInfor: '現場情報',
    siteInfo: '現場情報',
    detailInfo: 'CSV追加情報',
    detailInfor: 'CSV追加情報',
    additionInfor1: '追加情報',
    additionInfo1: '追加情報',
    document: 'ドキュメント情報',
    reference: '参考',
    detail: '詳細（一部編集可)',
  },

  detail_title_list_order_center: {
    receiveAll: '全情報',
    receiveInfo: '受注情報',
    appointInfo: 'アポイント情報',
    conditionInfo: '稼働調整情報',
    preparationInfo: '稼働待ち情報',
    siteInfo: '当日現場情報',
    report_uploadInfo: '報告書回収情報',
    deliveredInfo: '報告書納品情報',
    cancelMenu: 'キャンセル依頼',
    cancelInfo: 'キャンセル一覧',
    clientCompany: '基本情報',
    applicationCompany: '物件情報',
    detailInfo: 'CSV追加情報',
    additionInfo1: '追加情報',
    document: 'ドキュメント情報',
    reportUploadInfo: '報告書回収情報',
    allInfo: '全情報',
    visitDate: '訪問日',
    management: '協力企業',
    operationSetting: '個別物件設定変更',
    mattersSent: '申し送り事項',
    fileUpload: '作成資料',
    visitDateWorker: '訪問日時・作業員',
    appointmentInfo: 'アポイント情報',
    operationAdjustmentInfo: '稼働調整情報',
    priorConfirmationStatus: '事前確認状況',
    workerSupportStatus: '作業員対応状況',
    variousApplications: '各種申請',
    commentSection: 'コメント欄',
    result: '作業結果',
    acceptanceInformation: '検収情報',
    report: '報告書',
    reportSubmissionInformation: '報告書提出情報',
    cancelResult: 'キャンセル結果',
    cancelList: 'キャンセル一覧',
    unoperationList: '稼働不可一覧',
    detailedInformation: 'CSV追加情報',
    history: '操作履歴',
    fileDownload: '作成資料',
    workEstimate: '作業見積',
  },

  detail_title_list_order_partner: {
    clientCompany: '基本情報',
    applicationCompany: '物件情報',
    visitDate: '訪問日',
    mattersSent: '申し送り事項',
    fileDownload: '作成資料',
    workEstimate: '作業見積',
    detailInfo: 'CSV追加情報 ',
    document: 'ドキュメント情報 ',
    history: '操作履歴 ',
    fileUpload: '作成資料',
    appointInfo: 'アポイント情報',
    result: '結果',
    acceptanceInformation: '検収情報',
    report: '報告書',
    reportSubmissionInformation: '報告書提出情報',
    priorConfirmationStatus: '事前確認状況',
    visitDateWorker: '訪問日時・作業員',
    statusWorkerAllocation: '作業員対応状況',
    variousApplications: '各種申請',
    commentSection: 'コメント欄',
  },

  detail_title_list_order_client: {
    clientCompany: '基本情報',
    applicationCompany: '物件情報',
    preparationInfo: '依頼一覧',
    detailInfo: 'CSV追加情報',
    document: 'ドキュメント情報',
    history: '操作履歴',
    cancelMenu: 'キャンセル依頼',
    deliveredClient: '日付',
    receiveAll: '全情報',
    cancelInfo: 'キャンセル一覧',
    stileInfor: '現場情報',
    reportUploadInfo: '報告書回収情報',
    deliveredInfo: '報告書納品情報',
    additionInfo1: '追加情報',
    siteInfo: '当日現場情報',
    priorConfirmationStatus: '事前確認状況',
    visitDateWorker: '訪問日時・作業員',
    statusWorkerAllocation: '作業員対応状況',
    variousApplications: '各種申請',
    commentSection: 'コメント欄',
    fileUpload: '作成資料',
    report: '報告書',
  },

  table_order_example: {
    managementCode: '管理コード',
    applicantCompanyName: '申込者建物名',
    visitedDistrict: '訪問先部道府県 ',
    visitedAddress: '訪問先極点住所',
    visitedBase: '訪問先極点',
    homeConstruction: '宅内工事',
    openingTest: '開通試験',
    siteInvestigation: '現場調査',
    voiceHomeConstruction: '音声宅内工事',
    voiceSiteInvestigation: '音声現場調査',
    placeInvestigation: '現地調査',
    caseName: '案件名',
    partnerName: 'パートナー名',
    process: '工程',
    prefecturesVisited: '都道府県',
    reportReceiptDate: '申し送り事項',
    requestedDate: '作成日',
    resultsPreparation: '作業結果',
    visitDate: '訪問日',
    installationSiteName: '物件名',
    //visitedBaseName: '物件名',
    visitedBaseAddress: '訪問先拠点住所',
    contactInfoPersonInCharge: '申込担当者連絡先',
    applicationPerson: '申込担当者',
    serviceName: 'Service',
    desiredVisitDate5From: '訪問希望日⑤_From',
    scheduledInstallationLocation: '設置先拠点名',
    applicantBuildingName: '申込者建物名',
    desiredDateAndTimeOfVisitTo4: '希望訪問日時④（開始時間帯_to）',
    desiredDateAndTimeOfVisitFrom4: '希望訪問日時④（開始時間帯_from）',
  },

  table_device_list: {
    nameDevice: '端末名',
    productSchema: 'プロダクトスキーマ',
    createdAt: '作成時間 ',
  },

  table_my_page: {
    cloudId: 'CloudId',
    timeRequest: '申請日時',
    type: '種別',
    nameRequest: '依頼件名',
    status: 'ステータス',
    requestDatetime :'依頼日時',
    clientName :'発注企業名',
    productName: '工事名',
    projectName: 'プロジェクト名',
    serviceName : '工程名',
    approved : '承認対象',
    requestedDate : '作成日',
    visitDate : '訪問日',
  },

  modal_setting_order: {
    title_setting_status_order: 'Order個別制御設定',
    create_new_order: '新規Order作成',
    order_execution_approval: 'Order実行承認',
    appointment: 'アポイント',
    operation_adjustment: '稼動調整',
    worker_allocation: '作業員割当',
    confirmed_the_day_before: '事前確認',
    confirmed: '完了確認',
    report_collection: '報告書回収',
    report_delivery: '報告書納品',
    acceptance_confirmation: '検収確認',
    save: '設定を保存',
    use: '利用',
    unused: '未使用',
    option: '状況的に利用',
    setting: '設定',
    title_confirm_setting: 'Workerメニュー設定_端末設置',
    update_success: 'Update Plugin Setting Success',
    update_fail: 'Update Plugin Setting Fail',
  },

  modal_setting_order_email: {
    title_setting_status_order: 'Order個別制御設定_email',
    approval: '承認',
    appointment: 'アポイント',
    operation_adjustment: '稼働調整',
    operator: '作業員',
    prior_confirmation: '事前確認',
    operates_on_the_day: '当日稼働',
    report_collection: '報告書回収',
    report_delivery: '報告書納品',
    cancel: 'キャンセル',
    contract_management: '契約管理',
    save: '設定を保存',
    use: '利用',
    unused: '未使用',
    option: '状況的に利用',
    setting: '設定',
    title_confirm_setting: 'Workerメニュー設定_端末設置',
    update_success: 'Update Email Setting Success',
    update_fail: 'Update Email Setting Fail',
    restore: 'Restore Default Email Setting Success',
  },

  accounting_header: {
    productName: 'Product',
    projectName: 'プロジェクト名',
    acceptanceMonth: '検収月',
    localBase: '現地基本',
    localFollowUp: '現地追',
    localDepartment: '現地部',
    farFromTheSite: '現地遠',
    orderNumber: 'Order数',
    state: '状況',
    companyName: '会社名',
    serviceName: 'Service',
    sales: '売上（OP）',
    payment_date: '支払月',
    payment: '支払',
    Paymentop: '支払（OP）',
    earnings: '売上',
    earnings_OP: '',
    orderNumberAcceptance: 'Order数',
    paymentMonth: '支払月',
    orderNumberPayment: 'Order数',
  },

  menu_partner: {
    my_corp: '基本情報',
    setting_worker: 'Worker設定',
    aggregate: '集計サマリ設定',
    organization_master: '取引先登録',
    user_master: '利用者登録',
    setting_order: 'Order設定',
    anken_master: 'ワークフロー設定',
    staff_master: 'Worker利用者登録',
    branch_master: '拠点登録',
    dispatch: 'ディスパッチ',
    csv_master: 'CSV出力設定',
    hiring: '募集',
    negotiation_contract: "交渉・契約",
    contract: '契約管理',
    payment_date: '検収確認',
    recruitment: '応募',
    application: '募集',
    partner_application: '応募',
    partner_negotiation_contract: "契約管理",
    //accounting: '精算管理',
    calendar_master: 'カレンダーマスタ',
    workingSchedule: 'スケジュール',
    recordCorrection: 'レコード修正',
    importcsv_pri_sco: "工事物件取込設定"
    // payment_date: 'Payment Date'
  },

  detail_condition_info_center: {
    checkAll: 'Check all',
    partnerCode: 'コード',
    partnerName: '名前',
    klm: '距離(km)',
    people: '該当ワーカー',
    status_partner: 'パートナー状況',
  },

  list_check_status_order: {
    cancelWorker: 'ワーカーをキャンセルする',
    NotYetWrittenReport: '未記入のレポート',
    wroteReport: 'レポートを書く',
    sendReport: '報告を送信',
  },

  list_screen_export_setting: {
    receive_all: '全情報',
    receive_info: '受注情報',
    appoint_info: 'アポイント情報',
    condition_info: '稼働調整情報',
    preparation_info: '稼働待ち情報',
    site_info: '当日現場情報',
    report_upload_info: '報告書回収情報',
    delivered_info: '報告書納品情報',
    cancel_menu: 'キャンセル依頼',
    cancel_info: 'キャンセル一覧',
    tomorrow_info: '事前確認',
    unoperation_list: '稼働不可一覧',
  },

  list_screen_export_setting_partner: {
    receive_all: '稼働調整',
    receive_info: '受注情報',
    appoint_info: 'アポイント情報',
    condition_info: '稼働調整情報',
    preparation_info: '受注一覧',
    site_info: '当日稼働状況',
    report_upload_info: '報告書未提出',
    delivered_info: '報告書提出済',
    cancel_menu: 'キャンセル一覧',
    cancel_info: 'キャンセル一覧',
    tomorrow_info: '事前確認',
    unoperation_list: '稼働不可一覧',
  },

  list_screen_export_setting_client: {
    receive_all: '依頼進捗状況',
    receive_info: '新規物件作成',
    appoint_info: 'アポイント情報',
    condition_info: '稼働調整情報',
    preparation_info: '依頼一覧',
    site_info: '本日稼働状況',
    report_upload_info: '報告書未受領一覧',
    delivered_info: '報告書納品一覧',
    cancel_menu: 'キャンセル依頼',
    cancel_info: 'キャンセル一覧',
    tomorrow_info: '事前確認',
    unoperation_list: '稼働不可一覧',
  },

  menu_list: {
    button_export_csv: 'CSV出力',
  },

  organization_detail: {
    organization_code: '組織コード',
    organization_name: '組織名',
    company_name: '会社名',
    address: '住所',
  },

  areas: {
    kanto: '関東',
    kansai: '関西',
    koshinetsuAndHokuriku: '甲信越・北陸',
    china: '中国',
    hokkaidoAndTohoku: '北海道・東北',
    kyushuAndOkinawa: '九州・沖縄',
    shikoku: '四国',
    tokai: '東海',
  },

  import_primary: {
    STRING: 'テキスト',
    DATE: '日付(yyyy/mm/dd)',
    TIME: '時刻(hh:mm)',
    TIMESTAMP: '時間_ボタン',
    NUMBER: '数字 ',
    BARCODE:'バーコード識別',
  },

  payment_date: {
    back: '戻る',
    title: '当日現場情報',
    acceptance_confirmation: '検収月',
    payment_date: '支払月',
    save: '保存',
    edit: '更新',
    no: 'No',
    operation: '操作',
  },

  dispatch: {
    option1: 'フィールド1',
    option2: 'フィールド2',
    option3: 'フィールド3',
    option4: 'フィールド4',
    option5: 'フィールド5',
    save: '保存',
    cancel: 'キャンセル',
    label: '対象フィールド',
  },

  history_translation : {
    visitDate :'指定訪問日時',
    visitDateTo: '終了予定',
    visitDateFrom: '開始予定',
    erpMattersToBeSent:'契約企業申し送り事項',
    appointmentCategory:'アポイント区分',
    admissionApplicationDeadline:'事前確認期限',
    desiredVisitDate1From:'訪問希望日①_From',
    desiredVisitDateAndTime1:'希望訪問日時①_開始時間_from',
    desiredDateAndTimeOfVisit1:'希望訪問日時①_終了時間_to',
    desiredVisitDate1To:'訪問希望日①_To',
    desiredVisitDate2From:'訪問希望日②_From',
    desiredDateAndTimeOfVisitFrom2:'希望訪問日時②_開始時間_from',
    desiredDateAndTimeOfVisitTo2:'希望訪問日時②_終了時間_to',
    desiredVisitDate2To:'訪問希望日②_To',
    desiredVisitDate3From:'訪問希望日③_From',
    desiredDateAndTimeOfVisitFrom3:'希望訪問日時③_開始時間_from',
    desiredDateAndTimeOfVisitTo3:'希望訪問日時③_終了時間_to',
    desiredVisitDate3To:'訪問希望日③_To',
    desiredDateOfVisit4From:'訪問希望日④_From',
    desiredDateAndTimeOfVisitFrom4:'希望訪問日時④_開始時間_from',
    desiredDateAndTimeOfVisitTo4:'希望訪問日時④_終了時間_to',
    desiredDateOfVisit4To:'訪問希望日④_To',
    desiredVisitDate5From:'訪問希望日⑤_From',
    desiredDateAndTimeOfVisitFrom5:'希望訪問日時⑤_開始時間_from',
    desiredDateAndTimeOfVisitTo5:'希望訪問日時⑤_終了時間_to',
    callStatus:'架電ステータス',
    callLog:'架電メモ	',
    newCallDate:'次回架電日',
    newCallDateTo:'次回架電日',
    newCallDateFrom:'次回架電時間',
    applicantCompanyName:'会社名',
    personInChargeOfApplication:'氏名',
    contactPersonForApplication:'連絡先	',
    applicantEmailAddress:'メール',
    installationSiteName:'物件名',
    contactPersonInCharge:'訪問先ご担当者',
    contactInformation:'訪問先ご担当者連絡先',
    visitEmailAddress:'訪問先メールアドレス',
    partners:'協力企業選択',
    workerResponseRequired: '作業員返答区分',
    workerName:'作業員名',
    staffId:'スタッフID',
    startTime:'入館',
    endTime:'退館',
    resultCancel:'結果備考',
    resultsPreparation:'結果',
    partnerId :'協力企業ID',
    partnerName :'Management略称名',
    reasonForCancellation: '結果備考',
    managementMattersToBeSent :'協力企業申し送り事項',
    estimate :'見積金額',
    quoteMemo :'見積メモ',
    statusMonitoring : 'ステータス',
    approvalRequestMemo : '承認依頼メモ',
    waitingForResponse: '対応待ち',
    underAdmissionApplication: '対応中',
    correspondenceCompleted: '対応完了',
    cancelRequest: 'キャンセル依頼',
    cancel: 'キャンセル',
    withdrawal: '対応保留',
    workerUnallocated: '対応待ち',
    workersAreBeingAssigned: '対応中',
    preConfirmationCompleted: '対応完了',
    confirmationNotCompletedTheDayBefore: '前日確認未完了',
    operationCheck: '稼働確認',
    waitingForAdmission: '入館待ち',
    preConfirmationNotCompleted: '事前確認未完了',
    statusWorker: '作業員対応状況',
    timeLog: '予定時刻',
    contactLog: '連絡',
    commentLog: 'コメント',
    undefined: '',
    '': '',
    alertStartDate: 'アラート起算日',
    priorConfirmationDeadline: '入館申請期限',
    personInChargeAppoint: '架電先',
  },

  id: {
    completionResult: '完了結果',
    timeComment: '時間コメント',
    managementFileReportSecond: '管理ファイルレポート2番目',
    timestamp: 'タイムスタンプ',
    managementFileReportFirst: '管理ファイルレポートファースト',
    reportReturnDate: 'レポート返却日',
    erpFileReportFirst: 'erpファイルレポートを最初に',
    erpFileReportSecond: 'erpファイルレポート2番目',
    desiredVisitDate1From: '希望の訪問日から1',
    desiredVisitDateAndTime1: '希望の訪問日時1',
    desiredDateAndTimeOfVisit1: 'ご希望のご来店日時1',
    desiredVisitDate2From: '希望の訪問日から2',
    desiredDateAndTimeOfVisitFrom2: '希望の訪問日時2',
    desiredDateAndTimeOfVisitTo2: 'ご希望のご来店日時2',
    desiredVisitDate3From: '希望の訪問日から3',
    desiredDateAndTimeOfVisitFrom3: '希望の訪問日時3',
    desiredDateAndTimeOfVisitTo3: 'ご希望のご来店日時3',
    desiredDateOfVisit4From: '希望の訪問日から4',
    desiredDateAndTimeOfVisitFrom4: '希望の訪問日時4',
    desiredDateAndTimeOfVisitTo4: 'ご希望のご来店日時4',
    desiredVisitDate5From: '希望の訪問日から5',
    desiredDateAndTimeOfVisitFrom5: '希望の訪問日時5',
    desiredDateAndTimeOfVisitTo5: 'ご希望のご来店日時5',
    organizationId: '組織コード',
    providerSchema: 'プロバイダースキーマ',
    permissionProviderSettingId: '権限プロバイダー設定',
    status: '状況',
    recruitmentContractFile: '採用契約書',
    name: '名前',
    key: 'キー',
    timeUpload: '時間のアップロード',
    role: '役割',
    codeColor: '背景色',
    colorText: '背景色',
    roles: '役割',
    center: 'センター',
    updatedAt: 'で更新',
    active: 'アクティブ',
    icon: 'アイコン',
    iconActive: 'アイコンがアクティブです',
    index: 'index',
    label: 'label',
    statusScreen: 'ステータス画面',
    serviceId: 'serviceId',
    sortNum: 'ソート番号',
    menuSettings: 'メニュー設定',
    0: '役割',
    1: '役割',
    2: '役割',
    3: '役割',
    4: '役割',
    projectCode: 'projectCode',
    projectName: 'プロジェクト名',
    product: 'product',
    method: '方法',
    updatedBy: 'チェンジャー',
    createdBy: 'によって作成された',
    versionServices: 'バージョンサービス',
    version: 'バージョン',
    startAt: '入館時間',
    endAt: '退館時間',
    effectiveness: '効果',
    serviceProductId: 'serviceProductId',
    sortKey: 'ソートキー',
    productId: 'productId',
    projectId: 'projectId',
    provider: 'provider',
    providerId: 'providerId',
    pluginSettings: 'プラグイン設定',
    isUsed: 'isUsed',
    extraData: '追加データ',
    nameKana: 'クライアント名カナ',
    officialName: '代表者名',
    officialNameKana: 'フリガナ',
    postalCode: '郵便番号',
    prefecture: '都道府県',
    address: '住所',
    addressKana: 'フリガナ',
    partnerId: 'partnerId',
    organizationCode: '組織コード',
    createdAt: 'で作成',
    userId: 'ユーザーID',
    loginId: '利用者ID',
    email: 'Eメール',
    firstName: '姓',
    lastName: '名',
    firstNameKana: 'セイ',
    lastNameKana: 'メイ',
    phoneNumber: 'ユーザー連絡先',
    userRole: 'Role選択',
    organization: '取引先登録',
    idOrganization: 'id組織',
    idRole: 'idロール',
    lastModified: '最終更新日',
    permissionId: '許可ID',
    tariffId: '関税ID',
    tariffName: '関税名',
    recruitmentContractId: '採用契約ID',
    credit: 'クレジット',
    files: 'ファイル',
    comment: 'コメント',
    state: '状況',
    creditInformation: 'クレジット情報',
    endDay: '終了日',
    claim: '請求',
    explanation: '説明',
    form: '形',
    payment: '支払い',
    taxDivision: '税務課',
    title: '題名',
    step: 'ステップ',
    startTimeRecruitment: 'タイムリクルートを開始',
    partners: 'パートナー',
    startTimeBusiness: 'タイムビジネスを始める',
    tree: '木',
    monitorName: 'モニター名',
    productSchema: '製品スキーマ',
    fieldMaster: 'フィールドマスター',
    keys: 'キー',
    isPrepared: '準備されています',
    menuItemsSettings: 'menuItem設定',
    nameScreenSetting: '名前画面設定',
    productIds: 'productIds',
    serviceIds: 'serviceIds',
    codeInStella: 'codeInStella',
    exportSetting: 'エクスポート設定',
    isDisplay: '表示されています',
    updateAt: 'で更新',
    appointmentCategory: 'アポイント区分',
    contactInformation: '訪問先ご担当者連絡先',
    visitedPostalCode: '訪問先郵便番号',
    prefecturesVisited: '都道府県',
    visitedBaseAddress: '訪問先拠点住所',
    visitedBuildingName: 'アポイント区分',
    preferredDate: '訪問日',
    visitDate5: '訪問日5',
    visitDate4: '訪問日4',
    visitDate3: '訪問日3',
    visitDate2: '訪問日2',
    visitDate1: '訪問日1',
    visitDateFrom: '訪問日',
    visitDateTo: '訪問日',
    visitDate: '訪問日',
    messageForPartnerCommunication: '契約企業申し送り事項',
    callLog: '架電メモ',
    callStatus: '対応中',
    newCallDate: '架電ステータス',
    contactPersonInChargeStatus: '連絡先',
    personInChargeOfApplicationStatus: '訪問先ご担当者',
    applicantCompanyName: '申込会社名',
    personInChargeOfApplication: '申込ご担当者',
    contactPersonForApplication: '申込ご担当者連絡先',
    workerResponseRequired: '作業員返答必須',
    notificationItems: '申し送り事項',
    visitedSiteName: '物件名',
    contactPersonInCharge: '訪問先ご担当者',
    scheduledInstallationLocation: '作業予定箇所',
    statusPreparationInfo: 'ステータス準備情報',
    modifiedPreparationInfo: '変更された準備情報',
    timestampPreparationInfo: 'タイムスタンプ準備情報',
    endTime: '退館時間',
    recycle: '再投入を行う',
    resultCancel: '結果備考',
    resultsPreparation: '作業結果',
    statusSiteInfo: 'ステータスサイト情報',
    startTime: '入館時間',
    modifiedSiteInfo: '変更されたサイト情報',
    timestampSiteInfo: 'タイムスタンプサイト情報',
    reportConfirmedAt: '報告書確認日時',
    statusReportUploadInfo: 'ステータスレポートアップロード情報',
    modifiedReportUploadInfo: '変更されたレポートアップロード情報',
    timestampReportUploadInfo: 'タイムスタンプレポートアップロード情報',
    reportDeliveryDate: 'レポート納期',
    defectiveReturnContents: '不備返却コメント',
    isConfirmReportPartner: 'レポートパートナーの確認',
    screenName: 'ハンドルネーム',
    resultStatus: 'キャンセル理由',
    statusCancelMenu: 'ステータスキャンセルメニュー',
    settingCancel: 'キャンセルの設定',
    settingCancelResult: '結果のキャンセルの設定',
    result: '結果',
    pullDownDisplay: 'ディスプレイをプルダウン',
    isDefault: 'デフォルトです',
    id: 'id',
    statusCancelInfo: 'ステータスキャンセル情報',
    modifiedCancelInfo: '変更されたキャンセル情報',
    timestampCancelInfo: 'タイムスタンプキャンセル情報',
    confirmedTheDayBefore: '前日確認',
    dispatch: 'ディスパッチ',
    idProject: 'idProject',
    paymentMonth: '支払月',
    acceptanceMonth: '検収月',
    statusConfirmPartner: 'ステータス確認パートナー',
    statusOrderCondition: 'ステータス注文条件',
    listConfirmStaff: 'リスト確認スタッフ',
    staffId: 'スタッフID',
    workerName: '労働者名',
    scheduledWorkerInformation: 'スケジュールされた労働者情報',
    japaneseFields: '日本のフィールド',
    nameDevice: '名前デバイス',
    field1: 'フィールド1',
    field2: 'フィールド2',
    field3: 'フィールド3',
    field4: 'フィールド4',
    field5: 'フィールド5',
    field6: 'フィールド6',
    field7: 'フィールド7',
    field8: 'フィールド8',
    field9: 'フィールド9',
    field10: 'フィールド10',
    field11: 'フィールド11',
    field12: 'フィールド12',
    field13: 'フィールド13',
    field14: 'フィールド14',
    managementCode: '管理コード',
    orderPlace: '発注元',
    orderDate: '発注日',
    expectedDate: '入庫予定日',
    unit: '単位',
    quantity: '量',
    receivedQuantity: '受け取った数量',
    producer: 'プロデューサー',
    deviceId: 'デバイスID',
    receiptDate: '受領日',
    personInChargeOfVisiting: '訪問担当者',
    requestedDate: '作成日',
    reasonForCancellation: 'キャンセルの理由',
    canceledAt: 'キャンセル日',
    describeFiles: 'ファイルの説明',
    completionResultSetting: '作業結果設定',
    cancelSetting: '設定をキャンセル',
    alertSettings: 'アラート設定',
    reference: '参照',
    screen: '画面',
    value: '値',
    isEnabled: '有効になっています',
    customerCode: '顧客コード',
    tenantCode: '保持コード',
    tariffSettingId: '関税設定ID',
    serviceName: 'Service',
    productName: '商品名',
    projects: 'projects',
    endTimeRecruitment: '終了時間の募集',
    endTimeBusiness: 'エンドタイムビジネス',
    businessContent: 'ビジネスコンテンツ',
    necessaryResults: '必要な結果',
    scope: '範囲',
    money: 'お金',
    recruitmentType: '採用タイプ',
    recruitmentEstimateId: '募集見積もりID',
    roleId: 'ロールID',
    creatorConfirmed: '作成者確認済み',
    partnerConfirmed: 'パートナー確認済み',
    estimate: '見積もり',
    nda: 'nda',
    concludingDate: '締切日',
    contractPeriodStart: '契約期間開始',
    contractPeriodEnd: '契約期間終了',
    alert: 'アラート',
    contractStatus: '契約状況',
    contractType: '契約タイプ',
    partnerRejected: 'パートナーが拒否されました',
    creatorRejected: '作成者が拒否されました',
    moduleManagement: 'メニュー表示設定',
    partner: 'partner',
    client: 'client',
    reasonCancelWorker: '理由キャンセルワーカー',
    statusCancelWorker: 'ステータスワーカーのキャンセル',
    serviceOptions: 'サービスオプション',
    option1: 'オプション1',
    option2: 'オプション2',
    option3: 'オプション3',
    option4: 'オプション4',
    option5: 'オプション5',
    erpMattersToBeSent: 'erpの送信事項',
    preparationFile: '準備ファイル',
    url: 'URL',
    timeAddStaff: '時間スタッフを追加',
    workTime: '作業時間',
    labelSetting: 'ラベル設定',
    people: '人',
    options: 'オプション',
    staffSchema: 'スタッフスキーマ',
    cloudId: 'クラウドID',
    orderId: '注文ID',
    visitTime: '訪問時間',
    movingTime: '移動時間',
    distance: '距離',
    modified: '変更',
    5: 'id',
  },
};

export default ja_language;
